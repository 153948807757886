<template>
  <section class="content px-4 py-2 my-2" name="MassiveOrders">
    <v-layout wrap>
      <v-flex xs12>
        <AppTitle title="Blog" subtitle="---" />
      </v-flex>
      <v-flex xs12>
        <v-card>
          <v-card-text>
            <v-container grid-list-lg fluid>
              <v-layout row wrap>
                <v-flex xs12 sm12 md12>
                  <v-text-field v-model="item.name" label="Titulo" hide-details></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md6>
                  <v-select
                    v-model="item.idcat"
                    class="mt-2"
                    :items="categories"
                    item-text="name"
                    item-value="id"
                    label="Categorias"
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm12 md6>
                  <v-text-field v-model="item.url" label="URL (debe ser unico)" hide-details></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md6>
                  <img v-if="item.photo" :src="item.photo" style="max-width:100%;" />
                  <input ref="photo" type="file" style="display: none" @change="handlePhotoUpload('photo')" />
                  <v-btn v-if="!item.photo" color="primary" block depressed @click="$refs.photo.click()">
                    Subir Foto
                  </v-btn>
                  <v-btn v-else color="error" block depressed @click="item.photo = ''">
                    Quitar Foto
                  </v-btn>
                </v-flex>
                <v-flex xs12 sm12 md6>
                  <img v-if="item.portada" :src="item.portada" style="max-width:60%;" />
                  <input ref="portada" type="file" style="display: none" @change="handlePortadaUpload('portada')" />
                  <template v-if="!item.portada">
                    <v-btn color="primary" block depressed @click="$refs.portada.click()">
                      Subir Foto de portada
                    </v-btn>
                    <p class="caption">La imagen debe ser de 600x600</p>
                  </template>

                  <v-btn v-else color="error" block depressed @click="item.portada = ''">
                    Quitar Foto
                  </v-btn>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-textarea v-model="item.resume" label="Resumen"></v-textarea>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <template v-if="loadHtml">
                    <v-textarea v-model="item.description" label="Descripcion"></v-textarea>
                  </template>
                  <template v-else>
                    <QuillEditorFormat v-model="item.description" reference="description" />
                  </template>
                  <v-btn color="accent" style="margin:0" flat @click="changeHTML()">
                    <span v-if="loadHtml">Volver al editor</span>
                    <span v-else>Modificar HTML</span>
                  </v-btn>
                </v-flex>
                <v-flex xs12 sm6 md12>
                  <v-subheader>
                    Seo
                  </v-subheader>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-combobox
                    v-model="item.tags"
                    :items="tags"
                    item-text="name"
                    item-value="name"
                    multiple
                    small-chips
                    :return-object="false"
                    label="Etiquetas"
                  >
                    <template slot="item" slot-scope="data">
                      <span class="mr-0" v-html="data.item.name"></span>
                    </template>
                    <template slot="no-data">
                      <div class="pa-2 px-4">
                        No existe la etiqueta, presione Enter para agregar
                      </div>
                    </template>
                  </v-combobox>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-textarea v-model="item.seodetalle" label="Meta - descripcion"></v-textarea>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-textarea v-model="item.seokey" label="Meta - Palabras claves"></v-textarea>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-combobox
                    v-model="item.categories"
                    multiple
                    :items="categoriesAll"
                    item-text="name"
                    item-value="id"
                    label="Categorias de productos"
                  >
                    <template slot="selection" slot-scope="data">
                      <v-chip :selected="data.selected" close small @input="removeCategory(data.item.id)">
                        <span v-text="shortName(data.item.name)"></span>
                      </v-chip>
                    </template>

                    <template slot="item" slot-scope="data">
                      <span class="mr-0" v-html="data.item.name"></span>
                    </template>
                  </v-combobox>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-radio-group v-model="item.status" class="pa-0 ma-0" :mandatory="false">
                    <v-radio color="secondary" label="Inactivo" :value="false"></v-radio>
                    <v-radio color="secondary" label="Activo" :value="true"></v-radio>
                  </v-radio-group>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="accent" :loading="loading" :disabled="loading" flat @click="save">
              Listo
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </section>
</template>

<script>
/* eslint-disable vue/require-prop-type-constructor */
import { GET_BLOG, GET_BLOG_CATEGORIES, GET_BLOG_TAGS, CONFIG } from '../../../config'
import AppTitle from '../../useful/title.vue'
import QuillEditorFormat from '../../useful/quillEditorFormat.vue'

export default {
  name: 'SettingsBlogPost',
  components: {
    AppTitle,
    QuillEditorFormat
  },
  props: {
    id: {
      type: String | Number,
      default: 'nuevo'
    }
  },
  data() {
    return {
      loading: false,
      categories: [],
      item: {
        date: null,
        description: null,
        id: 0,
        idcat: 0,
        name: null,
        photo: null,
        resume: null,
        seodetalle: null,
        seokey: null,
        status: true,
        tags: null,
        url: null
      },
      loadHtml: false,
      categoriesAll: [],
      tags: []
    }
  },
  watch: {
    'item.name': function productName() {
      this.item.url = this.toSeoUrl(this.item.name)
    }
  },
  mounted() {
    this.getCategories_()
    this.getCategories()
    this.getBlogTags()
  },
  methods: {
    async get() {
      if (this.id !== 'nuevo') {
        try {
          const res = await this.$http.get(`${GET_BLOG}/${this.id}`)
          this.item = res.data
          if (this.item.categories.length > 0) {
            const categories = this.categoriesAll.filter(item => {
              return this.item.categories.indexOf(item.id) != -1
            })
            this.item.categories = categories
          }
        } catch (error) {
          this.$store.dispatch('changeSnack', {
            active: true,
            text: 'Ocurrio un error, intente nuevamente'
          })
        }
      }
    },
    async getCategories() {
      try {
        const res = await this.$http.get(GET_BLOG_CATEGORIES)
        this.categories = res.data
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async save() {
      const { item } = this
      const formData = new FormData()
      const categories = this.item.categories.map(e => {
        return e.id
      })
      if (this.id) formData.append('id', this.id)
      formData.append('date', item.date)
      formData.append('description', item.description)
      formData.append('id', item.id)
      formData.append('idcat', item.idcat)
      formData.append('name', item.name)
      formData.append('resume', item.resume)
      formData.append('seodetalle', item.seodetalle)
      formData.append('seokey', item.seokey)
      formData.append('status', item.status)
      formData.append('tags', item.tags)
      formData.append('url', item.url)
      formData.append('categories', categories)

      if (this.$refs.photo.files[0]) formData.append('photo', this.$refs.photo.files[0])
      if (this.$refs.portada.files[0]) formData.append('portada', this.$refs.portada.files[0])

      try {
        const res = await this.$http.post(GET_BLOG, formData)
        this.$router.push({ name: 'settingsBlogPost', params: { id: res.data.id } })
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async getCategories_() {
      try {
        const res = await this.$http.get(`${CONFIG}/categories`)
        this.categoriesAll = res.data
        setTimeout(() => {
          this.get()
        }, 300)
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async getBlogTags() {
      try {
        const res = await this.$http.get(`${GET_BLOG_TAGS}`)
        this.tags = res.data
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    changeHTML() {
      this.loadHtml = !this.loadHtml
    },
    toSeoUrl(url) {
      return (
        url
          .toString()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replace(/\s+/g, '-')
          .toLowerCase()
          .replace(/&/g, '-and-')
          // eslint-disable-next-line no-useless-escape
          .replace(/[^a-z0-9\-]/g, '')
          .replace(/-+/g, '-')
          .replace(/^-*/, '')
          .replace(/-*$/, '')
      )
    },
    shortName(s) {
      if (typeof s === 'string') {
        const count = 40
        return s.slice(0, count) + (s.length > count ? '...' : '')
      }
      return ''
    },
    removeCategory(id) {
      this.item.categories = this.item.categories.filter(e => e.id !== id)
    },
    handlePhotoUpload(type) {
      const url = URL.createObjectURL(this.$refs[type].files[0])
      this.item.photo = url
    },
    handlePortadaUpload(type) {
      const url = URL.createObjectURL(this.$refs[type].files[0])
      this.item.portada = url
    }
  }
}
</script>
